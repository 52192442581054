import {AiOutlineHome} from "react-icons/ai";
import Logo from "../assets/images/logo.png";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {useState} from "react";
import {BiMovie} from "react-icons/bi";
import {RiMovie2Line} from "react-icons/ri";
import {HiOutlineUsers} from "react-icons/hi";
import {TbCategory,TbFileStack} from "react-icons/tb";
import {MdOutlineSettingsVoice} from "react-icons/md";
import {IoMdNotificationsOutline} from "react-icons/io";
import { HiOutlineChat } from "react-icons/hi";
import { MdPhoneInTalk } from 'react-icons/md';

function SideMenu() {
    const [showIndex, setShowIndex] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <aside className="h-screen bg-[#151935] w-[240px]">
            <div className="flex flex-col justify-center items-center mt-4">
                <img src={Logo} alt="Logo" className="h-[50px]"/>
            </div>

            <ul className="my-10">
                <Link to="/">
                    <li className={`text-white px-3 py-2 cursor-pointer flex items-center rounded-md ${location.pathname === '/' ? 'bg-[#1C2340]' : ''}`}>
                        <AiOutlineHome className="inline-block mr-2"/>
                        Dashboard
                    </li>
                </Link>

                <p className="px-3 pt-2 text-xs font-light text-gray-400">CONTENTS</p>

                <li className={`text-white px-3 py-2 cursor-pointer mb-1 flex flex-col rounded-md ${['/add-movies', '/all-movies'].includes(location.pathname) ? 'bg-[#1C2340]' : ''}`}
                    onClick={() => {
                        navigate("/add-movies")
                        setShowIndex("movies")
                    }}>
                    <div className="flex justify-between items-center w-full">
                        <div className="flex gap1 items-center">
                            <BiMovie className="inline-block mr-2"/> Movies
                        </div>
                        {showIndex === "movies" ? <IoIosArrowUp/> : <IoIosArrowDown/>} </div>
                </li>
                {showIndex === "movies" &&
                    <ul className="bg-[#1C2340] px-5 py-2 text-gray-400 text-sm">
                        <Link to="/add-movies">
                            <li className={`pb-1 ${location.pathname === '/add-movies' ? 'text-white font-medium' : ''}`}>Add
                                Movies
                            </li>
                        </Link>
                        <Link to="/all-movies">
                            <li className={`pb-1 ${location.pathname === '/all-movies' ? 'text-white font-medium' : ''}`}>All
                                Movies
                            </li>
                        </Link>
                    </ul>
                }

                <li className={`text-white px-3 py-2 cursor-pointer mb-1 flex flex-col rounded-md ${['/add-series', '/all-series'].includes(location.pathname) ? 'bg-[#1C2340]' : ''}`}
                    onClick={() => {
                        navigate("/add-series")
                        setShowIndex("series")
                    }}>
                    <div className="flex justify-between items-center w-full">
                        <div className="flex gap1 items-center">
                            <RiMovie2Line className="inline-block mr-2"/> TV Series
                        </div>
                        {showIndex === "series" ? <IoIosArrowUp/> : <IoIosArrowDown/>}</div>
                </li>
                {showIndex === "series" &&
                    <ul className="bg-[#1C2340] px-5 py-2 text-gray-400 text-sm">
                        <Link to="/add-series">
                            <li className={`pb-1 ${location.pathname === '/add-series' ? 'text-white font-medium' : ''}`}>Add
                                Series
                            </li>
                        </Link>
                        <Link to="/all-series">
                            <li className={`pb-1 ${location.pathname === '/all-series' ? 'text-white font-medium' : ''}`}>All
                                Series
                            </li>
                        </Link>
                    </ul>
                }

                <p className="px-3 pt-2 text-xs font-light text-gray-400">SPECIAL</p>
                <Link to="/users">
                    <li className={`text-white px-3 py-2 cursor-pointer flex items-center rounded-md ${location.pathname === '/users' ? 'bg-[#1C2340]' : ''}`}>
                        <HiOutlineUsers className="inline-block mr-2"/>
                        Users
                    </li>
                </Link>
                <Link to="/messages">
  <li
    className={`text-white px-3 py-2 cursor-pointer flex items-center rounded-md ${
      location.pathname === "/messages" ? "bg-[#1C2340]" : ""
    }`}
  >
    <HiOutlineChat className="inline-block mr-2" size={20} />
    Messages
  </li>
</Link>
<Link to="/channel">
  <li
    className={`text-white px-3 py-2 cursor-pointer flex items-center rounded-md ${
      location.pathname === "/channel" ? "bg-[#1C2340]" : ""
    }`}
  >
    <MdPhoneInTalk className="inline-block mr-2" size={20} />
    Channel
  </li>
</Link>
                <Link to="/genres">
                    <li className={`text-white px-3 py-2 cursor-pointer flex items-center rounded-md ${location.pathname === '/genres' ? 'bg-[#1C2340]' : ''}`}>
                        <TbCategory className="inline-block mr-2"/>
                        Genres
                    </li>
                </Link>
                <Link to="/vjs">
                    <li className={`text-white px-3 py-2 cursor-pointer flex items-center rounded-md ${location.pathname === '/vjs' ? 'bg-[#1C2340]' : ''}`}>
                        <MdOutlineSettingsVoice className="inline-block mr-2"/>
                        VJs
                    </li>
                </Link>

                <p className="px-3 pt-2 text-xs font-light text-gray-400">MORE</p>
                <Link to="/collections">
                    <li className={`text-white px-3 py-2 cursor-pointer flex items-center rounded-md ${location.pathname.startsWith('/collections') ? 'bg-[#1C2340]' : ''}`}>
                        <TbFileStack className="inline-block mr-2"/>
                        Collections
                    </li>
                </Link>
                <Link to="/subscriptions">
                    <li className={`text-white px-3 py-2 cursor-pointer flex items-center rounded-md ${location.pathname === '/subscriptions' ? 'bg-[#1C2340]' : ''}`}>
                        <HiOutlineUsers className="inline-block mr-2"/>
                        Subscriptions
                    </li>
                </Link>
                <Link to="/notifications">
                    <li className={`text-white px-3 py-2 cursor-pointer flex items-center ${location.pathname === '/notifications' ? 'bg-[#1C2340]' : ''}`}>
                        <IoMdNotificationsOutline className="inline-block mr-2"/>
                        Notifications
                    </li>
                </Link>
            </ul>
        </aside>
    )
}

export default SideMenu