import React, { useState, useEffect, useCallback, useRef } from "react";
import { supabase } from "../utils/supabase";
import EmojiPicker from "emoji-picker-react";
import { motion } from "framer-motion";
import { IoSend } from "react-icons/io5";
import { BsImage } from "react-icons/bs";
import { FaSmile } from "react-icons/fa";

export default function Messages() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [image, setImage] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messagesEndRef = useRef(null);
  const [user, setUser] = useState(null);

  // ✅ Fetch the logged-in user
  useEffect(() => {
    const getUser = async () => {
      const { data, error } = await supabase.auth.getUser();
      if (!error && data?.user) {
        setUser(data.user);
      } else {
        console.error("User authentication failed:", error);
      }
    };
    
    getUser();
  }, []);

  // ✅ Fetch messages from Supabase
  useEffect(() => {
    const fetchMessages = async () => {
      const { data, error } = await supabase
        .from("messages")
        .select("*")
        .order("created_at", { ascending: true });

      if (!error) setMessages(data);
      else console.error("Error fetching messages:", error);
    };

    fetchMessages();

    // ✅ Real-time listener for new messages
    const subscription = supabase
      .channel("messages")
      .on("postgres_changes", { event: "INSERT", schema: "public", table: "messages" }, (payload) => {
        setMessages((prev) => [...prev, payload.new]); // Update messages state when a new message is added
      })
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, []);

  // ✅ Scroll to bottom when messages update
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // ✅ Handle sending messages
  const handleSendMessage = useCallback(async () => {
    if (!input.trim() && !image) return;

    const newMessage = {
      sender_email: user?.email || "anonymous",
      message: input,
      type: image ? "image" : "text",
      url: image || null,
      created_at: new Date().toISOString(),
    };

    try {
      const { error } = await supabase.from("messages").insert([newMessage]);
      if (error) throw error;

      setInput("");
      setImage(null);
    } catch (err) {
      console.error("Error sending message:", err.message);
    }
  }, [input, image, user]);

  // ✅ Handle image upload to Supabase Storage
  // ✅ Handle image upload to Supabase Storage
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    // Validate file type
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedTypes.includes(file.type)) {
      console.error("Invalid file type. Only images are allowed.");
      return;
    }
  
    // Validate file size (e.g., 5MB limit)
    const maxSize = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSize) {
      console.error("File size exceeds 5MB limit.");
      return;
    }
  
    const filePath = `images/${Date.now()}_${file.name}`;
  
    try {
      // Upload the file to the correct bucket
      const { data, error } = await supabase.storage
        .from("image_messages") // Bucket name
        .upload(filePath, file);
  
      if (error) {
        console.error("Upload error:", error.message);
        return;
      }
  
      // Get the public URL of the uploaded file
      const { data: publicUrlData, error: urlError } = await supabase.storage
        .from("image_messages") // Same bucket name
        .getPublicUrl(filePath);
  
      if (urlError) {
        console.error("Error fetching public URL:", urlError.message);
        return;
      }
  
      // Set the image URL in state
      setImage(publicUrlData.publicUrl);
    } catch (err) {
      console.error("Unexpected error during upload:", err);
    }
  };
  

  // ✅ Add emoji to input field
  const addEmoji = useCallback((emojiObject) => {
    setInput((prev) => prev + emojiObject.emoji);
  }, []);

  return (
    <div className="flex flex-col h-screen bg-gray-100 p-4">
      {/* Chat Header */}
      <div className="bg-blue-600 text-white p-4 rounded-t-lg shadow-md text-lg font-semibold">
        Chat Room
      </div>

      {/* Messages Container */}
      <div className="flex-1 overflow-y-auto space-y-4 p-2">
        {messages.map((msg, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className={`flex ${msg.sender_email === user?.email ? "justify-end" : "justify-start"}`}
          >
            <div
              className={`max-w-xs p-3 rounded-lg shadow-md ${
                msg.sender_email === user?.email
                  ? "bg-blue-500 text-white rounded-br-none"
                  : "bg-gray-200 text-black rounded-bl-none"
              }`}
            >
              {msg.type === "text" ? (
                <p>{msg.message}</p>
              ) : (
                <img src={msg.url} alt="Sent" className="rounded-lg w-40 h-40 object-cover" />
              )}
              {msg.sender_email !== user?.email && (
                <p className="text-xs text-gray-500 mt-1">{msg.sender_email}</p>
              )}
            </div>
          </motion.div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Message Input */}
      <div className="flex items-center gap-2 p-2 bg-white shadow-md rounded-b-lg">
        <input
          type="text"
          placeholder="Type a message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="flex-1 bg-white text-black border border-gray-300 rounded-full p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-500"
        />

        {/* Image Upload Button */}
        <input type="file" accept="image/*" onChange={handleImageUpload} className="hidden" id="image-upload" />
        <label htmlFor="image-upload" className="cursor-pointer bg-gray-200 p-2 rounded-full">
          <BsImage className="text-gray-600" size={20} />
        </label>

        {/* Emoji Picker Button */}
        <button onClick={() => setShowEmojiPicker(!showEmojiPicker)} className="bg-gray-200 p-2 rounded-full">
          <FaSmile className="text-gray-600" size={20} />
        </button>

        {showEmojiPicker && (
          <div className="absolute bottom-20 right-4">
            <EmojiPicker onEmojiClick={addEmoji} />
          </div>
        )}

        {/* Send Button */}
        <button onClick={handleSendMessage} className="bg-blue-500 text-white p-2 rounded-full">
          <IoSend size={22} />
        </button>
      </div>
    </div>
  );
}
