import React from 'react';
import {ErrorMessage} from "formik";
import classnames from "classnames"

const FormField = ({name, label, children, className = ""}) => {
    return (
        <div className={classnames('flex flex-col gap-1 mb-3', className)}>
            <label htmlFor={name ?? label.toLocaleLowerCase()}>{label}</label>
            {children}
            <ErrorMessage name={name}>
                {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                        <p>{msg}</p>
                    </div>
                )}
            </ErrorMessage>
        </div>
    );
};

export default FormField;