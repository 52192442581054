import React, {useMemo} from 'react';
import {supabase} from "../../utils/supabase";
import {AddCollectionSchema} from "../../utils/validators";
import {Form, Formik} from "formik";
import FormField from "../../common/FormField";
import ContentTypeAsyncSelect from "../../common/ContentTypeAsyncSelect";
import ContentAsyncSelect from "../../common/ContentAsyncSelect";
import {MdAdd} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import CategoryAsyncSelect from "../../common/CategoryAsyncSelect";

const AddCollection = () => {
    const navigate = useNavigate();

    const types = useMemo(() => ["Latest", "Popular", "Trending", "Category", "VJ", "Genre (Not Working Yet)", "Featured"], []);
    const contexts = useMemo(() => ["All", "Series", "Movies"], []);

    const handleSubmit = async (values, resetForm) => {

        const {data, error} = await supabase
            .from('collections')
            .insert({
                name: values.name,
                active: true,
                order: values.order,
                is_generated: !!values.type.trim(),
                content_type: values.type,
                content_type_id: values?.content_type_id?.value,
                context: values.context
            })
            .select()
            .single()

        if (error) {
            alert(error.message)
            return false;
        }

        if (data && values.contents) {

            const {error} = await supabase
                .from('collections_content')
                .insert(
                    values.contents.map(item => ({
                        collection_id: data.id,
                        content_id: item.value
                    }))
                )

            if (error) {
                alert(error.message)
                return false;
            }

        }

        resetForm()

        navigate("/collections")
    }

    return (
        <div className='w-full h-screen overflow-y-scroll py-5'>
            <div className='flex justify-between w-full items-center'>
                <div>
                    <h1>Add Collection</h1>
                    <p className='text-gray-400'>Add Homepage Row</p>
                </div>
            </div>

            <Formik
                initialValues={{name: "", order: 1, type: "", context: ""}}
                enableReinitialize
                validationSchema={AddCollectionSchema}
                onSubmit={(values, {resetForm}) => handleSubmit(values, resetForm)}>
                {({values, handleChange, handleBlur, setFieldValue}) => (
                    <Form>
                        <div className={"grid md:grid-cols-2 grid-cols-1 gap-x-2 gap-y-3 my-2 px-2"}>

                            <FormField label={"Name"} name={"name"}>
                                <input type="text" name="name" id="name" placeholder='Enter name of collection'
                                       className='bg-transparent border border-gray-400 px-3 py-2 rounded'
                                       onChange={handleChange("name")} onBlur={handleBlur("name")}
                                       value={values.name}
                                />
                            </FormField>

                            <FormField label={"Order"} name={"order"}>
                                <input type="text" name="order" id="order" placeholder='Enter order of collection'
                                       className='bg-transparent border border-gray-400 px-3 py-2 rounded'
                                       onChange={handleChange("order")} onBlur={handleBlur("order")}
                                       value={values.order}
                                />
                            </FormField>

                            <FormField label={"Type"} name={"type"}>
                                <select
                                    className='bg-transparent border border-gray-400 px-3 py-2 rounded text-xs text-gray-400 mt-1'
                                    onChange={(event) => setFieldValue("type", event.target.value)}>
                                    <option value="">Manual</option>
                                    {types.map((type, index) => (
                                        <option value={type.toLowerCase()} key={index}>{type}</option>
                                    ))}
                                </select>
                            </FormField>

                            <FormField label={"Context"} name={"context"}>
                                <select
                                    className='bg-transparent border border-gray-400 px-3 py-2 rounded text-xs text-gray-400 mt-1'
                                    onChange={(event) => setFieldValue("context", event.target.value)}>
                                    <option value="">....</option>
                                    {contexts.map((type, index) => (
                                        <option value={type.toLowerCase()} key={index}>{type}</option>
                                    ))}
                                </select>
                            </FormField>

                            {
                                values["type"] === "category" &&
                                (
                                    <FormField label={"Category"} name={"content_type_id"}
                                               className={"col-span-full"}>
                                        <CategoryAsyncSelect/>
                                    </FormField>
                                )
                            }
                            {
                                values["type"] === "vj" &&
                                (
                                    <FormField label={"VJ"} name={"content_type_id"} className={"col-span-full"}>
                                        <ContentTypeAsyncSelect/>
                                    </FormField>
                                )
                            }

                            <FormField label={"Content"} name={"contents"}
                                       className={"col-span-full"}>
                                <ContentAsyncSelect/>
                            </FormField>

                            <div className="col-span-full flex justify-end mb-5">
                                <button type="submit"
                                        className='px-3 py-2 text-white font-medium bg-sky-500 flex items-center gap-1 text-sm rounded-md'>
                                    <MdAdd size={25}/>
                                    Create
                                </button>
                            </div>

                        </div>
                    </Form>
                )}
            </Formik>
        </div>

    );
};

export default AddCollection;