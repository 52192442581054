import {useEffect, useState, useCallback, useRef} from "react";
import {supabase} from "../utils/supabase";
import DeleteUserModal from "../components/DeleteUser";
import EditSubscriptionModal from "../components/EditSubscription";

// Debounce function to delay fetching during typing
const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

const PAGE_SIZE = 30;

function Users() {
    const [users, setUsers] = useState([]);
    const [deleteUser, setDeleteUser] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const observerRef = useRef(null);
    //
    const [selectedIndex, setSelectedIndex] = useState(null);
    // NEW STATE
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

    useEffect(() => {
        // Initial fetch for users when component mounts
        fetchUsers(true);
    }, [debouncedSearchTerm]);

    console.log("SelectedIndex", selectedIndex)

    // Function to fetch all users
    const fetchUsers = async (reset = false) => {
        if (loading) return;

        setLoading(true);

        let query = supabase.from("users_view")
            .select("*")
            .order("created_at", {ascending: false})
            .range(reset ? 0 : page * PAGE_SIZE, (page + 1) * PAGE_SIZE - 1);

        if (debouncedSearchTerm) {
            query = query.ilike("email", `%${debouncedSearchTerm}%`)
            // .or(`name.ilike.%${debouncedSearchTerm}%`)
            // .or(`username.ilike.%${debouncedSearchTerm}%`);
        }

        const {data, error} = await query;

        if (error) {
            console.error("Error fetching users:", error);
            return;
        }

        setUsers(reset ? data : [...users, ...data]);
        setPage(reset ? 1 : page + 1);
        setHasMore(data.length === PAGE_SIZE);
        setLoading(false);
    };

    // Debounce Effect: Updates debouncedSearchTerm after delay
    useEffect(
        debounce(() => {
            setDebouncedSearchTerm(searchQuery)
        }, 300),
        [searchQuery]
    );

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                console.log("observed here", hasMore)
                if (entries[0].isIntersecting && hasMore) {
                    fetchUsers();
                }
            },
            {threshold: 1.0}
        );

        if (observerRef.current) observer.observe(observerRef.current);
        return () => observer.disconnect();
    }, [hasMore]);

    return (
        <div className="w-full h-screen overflow-y-scroll py-16">
            <div className="flex justify-between w-full items-center">
                <div>
                    <h1>Users</h1>
                    <p className="text-gray-400">Manage users</p>
                </div>
                <input
                    type="text"
                    placeholder="Search users..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.currentTarget.value)}
                    className="w-1/2 px-3 py-1 border border-blue-500 rounded-md text-black bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
            </div>

            <div className="mb-24 my-10 px-3 py-3 bg-[#1C2340] overflow-x-hidden">
                <div className="overflow-x-scroll">
                    <table className="w-full table-auto md:p-10 select-none">
                        <thead>
                        <tr className="border-b-[1px] border-gray-600">
                            <th className="py-2 text-left pl-3">#</th>
                            <th className="py-2 text-left pl-3">Name</th>
                            <th className="py-2 text-left pl-3">Email</th>
                            <th className="py-2 text-left pl-3">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map((user, index) => (
                            <tr
                                key={user.id}
                                className="cursor-pointer mb-10 text-gray-400 border-b-[1px] border-gray-700 hover:bg-gray-600/30"
                            >
                                <td className="py-2 text-left pl-3">{index + 1}</td>
                                <td className="py-2 text-left pl-3">
                                    {user.name ?? user.username}
                                </td>
                                <td className="py-2 text-left pl-3">{user.email}</td>
                                <td className="py-2 text-left pl-3">
                                    <button className="px-3 py-1 bg-green-500 text-white text-sm rounded-md me-2"
                                            onClick={() => setSelectedIndex(user.id)}>
                                        Subscribe
                                    </button>
                                    <button
                                        className="px-3 py-1 bg-sky-500 text-white text-sm rounded-md"
                                        onClick={() => setDeleteUser(user)}>
                                        Delete
                                    </button>
                                </td>
                                {deleteUser && deleteUser.id === user.id && (
                                    <DeleteUserModal user={user} setShowModal={setDeleteUser}/>
                                )}
                                {selectedIndex && selectedIndex === user.id && (
                                    <EditSubscriptionModal
                                        email={user.email}
                                        id={user.id}
                                        handleModalShowing={setSelectedIndex}
                                    />
                                )}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div ref={observerRef} className="h-10"></div>
                {loading && <p>Loading...</p>}
            </div>
        </div>
    );
}

export default Users;
