import { useState, useEffect } from "react";
import { supabase } from "../../utils/supabase";
import { Link } from "react-router-dom";
import DeleteCollectionModal from "../../components/DeleteCollection";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

// SortableItem component for each collection row
const SortableCollectionRow = ({ collection, index, onDelete }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ 
    id: collection.id.toString()
  });
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  
  return (
    <tr 
      ref={setNodeRef} 
      style={style}
      className="cursor-pointer mb-10 text-gray-400 border-b-[1px] border-gray-700 hover:bg-gray-600/30"
    >
      <td className="py-2 text-left pl-3" {...attributes} {...listeners}>
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 cursor-grab" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          {index + 1}
        </div>
      </td>
      <td className="py-2 text-left pl-3">{collection.name}</td>
      <td className="py-2 text-left pl-3">#{collection.order}</td>
      <td className="py-2 text-left pl-3">{collection.is_generated.toString().toUpperCase()}</td>
      <td className="py-2 text-left pl-3">{collection.content_type ?? 'Manual'}</td>
      <td className="py-2 text-left pl-3">{collection.context.toUpperCase()}</td>
      <td className="py-2 text-left pl-3 flex gap-1">
        <Link to={"/collections/" + collection.id}
              className='px-3 py-1 bg-[#1C2340] text-white text-sm rounded-md'>
          Edit
        </Link>
        <button className='px-3 py-1 bg-[#1C2340] text-white text-sm rounded-md'
                onClick={() => onDelete(collection)}>
          Delete
        </button>
      </td>
    </tr>
  );
};

function Collections() {
  const [collections, setCollections] = useState([]);
  const [deleteCollection, setDeleteCollection] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  // Configure the sensors for drag and drop
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    getCollections();
    const subscription = supabase
      .channel("public:collections")
      .on("postgres_changes", {event: "*", schema: "public", table: "collections"}, () => {
        getCollections();
      })
      .subscribe();
      
    return () => {
      subscription && supabase.removeChannel(subscription);
    };
  }, []);

  const getCollections = async () => {
    const {data, error} = await supabase
      .from('collections')
      .select("*,collections_content(count)")
      .order('order', {ascending: true});

    if (data) {
      setCollections(data);
    }
  };

  // Function to update orders in the database
  const updateOrders = async (updatedCollections) => {
    try {
      setIsSaving(true);
      
      // Update each collection's order in the database
      for (const item of updatedCollections) {
        const { error } = await supabase
          .from('collections')
          .update({ order: item.order })
          .eq('id', item.id);
          
        if (error) throw error;
      }
      
    } catch (error) {
      console.error('Error updating orders:', error);
      // If there's an error, get the collections again
      getCollections();
    } finally {
      setIsSaving(false);
    }
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    
    // Check if we have valid active and over items
    if (!active || !over || !active.id || !over.id) {
      console.log("Invalid drag operation", { active, over });
      return;
    }
    
    if (active.id !== over.id) {
      // Find the indexes
      const oldIndex = collections.findIndex(item => item.id.toString() === active.id);
      const newIndex = collections.findIndex(item => item.id.toString() === over.id);
      
      // Validate indexes
      if (oldIndex < 0 || newIndex < 0) {
        console.log("Invalid indices", { oldIndex, newIndex });
        return;
      }
      
      // Create a new array with the updated order
      const newCollections = [...collections];
      const [movedItem] = newCollections.splice(oldIndex, 1);
      newCollections.splice(newIndex, 0, movedItem);
      
      // Update the order property of each item
      const updatedCollections = newCollections.map((item, index) => ({
        ...item,
        order: index + 1
      }));
      
      // Optimistically update the UI
      setCollections(updatedCollections);
      
      // Update the database
      await updateOrders(updatedCollections);
    }
  };

  // Handle collection deletion and re-ordering
  const handleAfterDelete = async (deletedCollectionId) => {
    // Close the delete modal
    setDeleteCollection(null);
    
    // Filter out the deleted collection
    const remainingCollections = collections.filter(c => c.id !== deletedCollectionId);
    
    // Re-number the orders
    const reorderedCollections = remainingCollections.map((item, index) => ({
      ...item,
      order: index + 1
    }));
    
    // Update the UI first (optimistic update)
    setCollections(reorderedCollections);
    
    // Then update the database
    await updateOrders(reorderedCollections);
  };

  return (
    <div className='w-full h-screen overflow-y-scroll py-5'>
      <div className='flex justify-between w-full items-center'>
        <div>
          <h1>Collections</h1>
          <p className='text-gray-400'>Manage Collections</p>
        </div>

        <div className="flex items-center">
          {isSaving && (
            <span className="text-gray-400 mr-3">Saving order...</span>
          )}
          <Link to={"/collections/add"}
                className='px-3 py-2 text-white font-medium bg-sky-500 text-sm rounded-md'>
            Add Collection
          </Link>
        </div>
      </div>
      
      <div className="mb-24 my-10 px-3 py-3 bg-[#1C2340]">
        <table className="w-full table-auto md:p-10 select-none overflow-visible">
          <thead>
            <tr className="border-b-[1px] border-gray-600">
              <th className="py-2 text-left pl-3">#</th>
              <th className="py-2 text-left pl-3">Name</th>
              <th className="py-2 text-left pl-3">Order</th>
              <th className="py-2 text-left pl-3">Generated</th>
              <th className="py-2 text-left pl-3">Type</th>
              <th className="py-2 text-left pl-3">Context</th>
              <th className="py-2 text-left pl-3">Actions</th>
            </tr>
          </thead>
          
          <tbody>
            {collections && collections.length > 0 ? (
              <DndContext 
                sensors={sensors} 
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
              >
                <SortableContext 
                  items={collections.map(c => c.id.toString())}
                  strategy={verticalListSortingStrategy}
                >
                  {collections.map((collection, index) => (
                    <SortableCollectionRow 
                      key={collection.id} 
                      collection={collection} 
                      index={index} 
                      onDelete={setDeleteCollection} 
                    />
                  ))}
                </SortableContext>
              </DndContext>
            ) : (
              <tr>
                <td colSpan="7" className="py-4 text-center text-gray-400">
                  {collections === null ? "Loading collections..." : "No collections found"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {deleteCollection && (
        <DeleteCollectionModal 
          setShowModal={setDeleteCollection} 
          collection={deleteCollection} 
          onAfterDelete={handleAfterDelete}
        />
      )}
    </div>
  );
}

export default Collections;