import React, {useCallback} from 'react';
import AsyncSelect from 'react-select/async';
import {useField} from "formik";
import {supabase} from "../utils/supabase";

const CategoryAsyncSelect = () => {
    const [{name, value, onBlur}, _, {setValue}] = useField("content_type_id");

    const loadOptions = useCallback((inputValue, callback) => {
        setTimeout(() => {
            if (inputValue.trim().length < 1) {
                supabase.from("content_categories")
                    .select("category,type")
                    .then(({data, error}) => {
                        if (error) console.log("error", error)
                        const map = data.filter(e => e.category).map(item => ({
                            value: item.category,
                            label: `${item.category} (${item.type})`
                        }));
                        callback([{value: "", label: "...."}, ...map]);
                    })
            } else {
                //
                supabase.from("content_categories")
                    .select("category,type")
                    .ilike("category", `%${inputValue}%`)
                    .then(({data, error}) => {
                        if (error) console.log("error", error)
                        const map = data.map(item => ({value: item.category, label: `${item.category} (${item.type})`}));
                        callback([{value: "", label: "...."}, ...map]);
                    })
            }
        }, 1000);
    }, []);

    return (
        <AsyncSelect
            name={name}
            cacheOptions={false}
            loadOptions={loadOptions}
            defaultOptions
            onBlur={onBlur}
            value={value}
            onChange={newValue => setValue(newValue)}
            classNames={{
                option: ({}) => `text-black`,
                menuList: ({}) => `text-black`,
                clearIndicator: ({}) => "text-red",
            }}
        />

    );
};

export default CategoryAsyncSelect;