import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {supabase} from "../../utils/supabase";
import {Form, Formik} from "formik";
import {AddCollectionSchema} from "../../utils/validators";
import FormField from "../../common/FormField";
import ContentTypeAsyncSelect from "../../common/ContentTypeAsyncSelect";
import ContentAsyncSelect from "../../common/ContentAsyncSelect";
import {MdAdd} from "react-icons/md";
import {difference} from "lodash"
import CategoryAsyncSelect from "../../common/CategoryAsyncSelect";

const EditCollection = () => {
    const {id} = useParams()
    const [data, setData] = useState({})
    const navigate = useNavigate();

    useEffect(() => {
        supabase.from("collections")
            .select("*,collections_content(*)")
            .eq("id", id)
            .single()
            .then(({data}) => {
                const result = {...data};

                if (result.content_type_id) {

                    if (result.content_type === "vj") {
                        supabase.from("vjs").select("id,name").eq("id", result.content_type_id).single()
                            .then(({data: vj}) => {
                                result.content_type_id = {value: vj.id, label: vj.name}
                                //
                                if (result.collections_content && result.collections_content.length) {
                                    supabase.from("movies_and_series").select("id,title,type").in("id", result.collections_content.map(e => e.content_id))
                                        .then(({data: movies}) => {
                                            result.contents = movies.map(e => ({
                                                label: `${e.title} (${e.type})`,
                                                value: e.id
                                            }))
                                            setData(result)
                                        })
                                } else {
                                    setData(result)
                                }
                            })
                    } else if (result.content_type === "category") {
                        result.content_type_id = {value: result.content_type_id, label: result.content_type_id}
                        setData(result)
                    }

                } else {
                    //
                    if (result.collections_content && result.collections_content.length) {
                        supabase.from("movies_and_series").select("id,title,type").in("id", result.collections_content.map(e => e.content_id))
                            .then(({data: movies}) => {
                                result.contents = movies?.map(e => ({label: e.title, value: e.id})) ?? []
                                setData(result)
                            })

                    } else {
                        setData(result)
                    }
                }
                // console.log("Data", result)
            })
    }, [setData]);

    const types = useMemo(() => ["Latest", "Popular", "Trending", "Category", "VJ", "Genre (Not Working Yet)", "Featured"], []);
    const contexts = useMemo(() => ["All", "Series", "Movies"], []);

    const handleSubmit = useCallback(async (values, resetForm) => {

        const {error} =
            await supabase
                .from('collections')
                .update({
                    name: values.name,
                    active: values.active,
                    order: values.order,
                    is_generated: !!values.type,
                    content_type: values.type,
                    content_type_id: values?.content_type_id?.value,
                    context: values.context
                })
                .eq("id", id)
                .single()

        if (error) {
            alert(error.message)
            return false;
        }

        if (data.contents || values.contents) {

            const old = (data.contents ?? []).map(e => e.value)
            const newer = (values.contents ?? []).map(e => e.value)

            const removed = difference(old, newer);
            const added = difference(newer, old);

            if (removed.length) {
                await supabase
                    .from('collections_content')
                    .delete()
                    .in("content_id", removed)
            }

            if (added.length) {
                await supabase
                    .from('collections_content')
                    .insert(added.map(item => ({collection_id: id, content_id: item})))

                if (error) {
                    alert(error.message)
                    return false;
                }
            }

        }

        resetForm()

        navigate("/collections")
    }, [id]);

    return (
        <div className='w-full h-screen overflow-y-scroll py-5'>
            <div className='flex justify-between w-full items-center'>
                <div>
                    <h1>{`Edit ${data.name} Collection`}</h1>
                    <p className='text-gray-400'>{`Edit ${data.order} Homepage Row`}</p>
                </div>
            </div>
            <Formik
                initialValues={{
                    name: data.name,
                    order: data.order,
                    type: data.content_type,
                    series: data.is_series,
                    active: data.active,
                    content_type_id: data.content_type_id,
                    contents: data.contents,
                    context: data.context
                }}
                enableReinitialize
                validationSchema={AddCollectionSchema}
                onSubmit={(values, {resetForm}) => handleSubmit(values, resetForm)}>
                {({values, handleChange, handleBlur, setFieldValue}) => (
                    <Form>
                        <div className={"grid md:grid-cols-2 grid-cols-1 gap-x-2 gap-y-3 my-2 px-2"}>

                            <FormField label={"Name"} name={"name"}>
                                <input type="text" name="name" id="name" placeholder='Enter name of collection'
                                       className='bg-transparent border border-gray-400 px-3 py-2 rounded'
                                       onChange={handleChange("name")} onBlur={handleBlur("name")}
                                       value={values.name}
                                />
                            </FormField>

                            <FormField label={"Order"} name={"order"}>
                                <input type="text" name="order" id="order" placeholder='Enter order of collection'
                                       className='bg-transparent border border-gray-400 px-3 py-2 rounded'
                                       onChange={handleChange("order")} onBlur={handleBlur("order")}
                                       value={values.order}
                                />
                            </FormField>

                            <FormField label={"Type"} name={"type"}>
                                <select
                                    defaultValue={values["type"]}
                                    className='bg-transparent border border-gray-400 px-3 py-2 rounded text-xs text-gray-400 mt-1'
                                    onChange={(event) => setFieldValue("type", event.target.value)}>
                                    <option value="">Manual</option>
                                    {types.map((type, index) => (
                                        <option selected={values.type === type.toLowerCase()} value={type.toLowerCase()}
                                                key={index}>{type}</option>
                                    ))}
                                </select>
                            </FormField>

                            <FormField label={"Context"} name={"context"}>
                                <select
                                    defaultValue={values["context"]}
                                    className='bg-transparent border border-gray-400 px-3 py-2 rounded text-xs text-gray-400 mt-1'
                                    onChange={(event) => setFieldValue("context", event.target.value)}>
                                    <option value="">....</option>
                                    {contexts.map((type, index) => (
                                        <option selected={values.context === type.toLowerCase()}
                                                value={type.toLowerCase()} key={index}>{type}</option>
                                    ))}
                                </select>
                            </FormField>

                            {
                                values["type"] === "category" &&
                                (
                                    <FormField label={"Category"} name={"content_type_id"} className={"col-span-full"}>
                                        <CategoryAsyncSelect/>
                                    </FormField>
                                )
                            }
                            {
                                values["type"] === "vj" &&
                                (
                                    <FormField label={"VJ"} name={"content_type_id"} className={"col-span-full"}>
                                        <ContentTypeAsyncSelect/>
                                    </FormField>
                                )
                            }
                            <FormField label={"Content (Movies OR Series)"} name={"contents"}
                                       className={"col-span-full"}>
                                <ContentAsyncSelect/>
                            </FormField>

                            <div className="col-span-full flex justify-end mb-5">

                                <FormField label={"Active"} name={"active"}>
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" name="active" id="active"
                                               onChange={handleChange("active")}
                                               onBlur={handleBlur("active")} value={values.active}
                                               checked={values.active} className="sr-only peer"/>
                                        <div
                                            className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                    </label>
                                </FormField>

                                <button type="submit"
                                        className='px-3 py-2 text-white font-medium bg-sky-500 flex items-center gap-1 text-sm rounded-md'>
                                    <MdAdd size={25}/>
                                    Create
                                </button>
                            </div>

                        </div>
                    </Form>
                )}
            </Formik>
        </div>

    );
};

export default EditCollection;