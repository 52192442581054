import React, {useCallback} from 'react';
import AsyncSelect from 'react-select/async';
import {useField} from "formik";
import {supabase} from "../utils/supabase";

const ContentAsyncSelect = () => {
    const [{name, value, onBlur, onChange}, _, {setValue}] = useField("contents");

    const loadOptions = useCallback((inputValue, callback) => {
        setTimeout(() => {
            if (inputValue.trim().length < 1) {
                callback([{value: "", label: "Select"}])
            } else {
                //
                supabase.from("movies_and_series")
                    .select("id,title,type")
                    .ilike("title", `%${inputValue}%`)
                    .then(({data, error}) => {
                        if (error) console.log("error", error)
                        callback(data.map(item => ({value: item.id, label: `${item.title} (${item.type})`})));
                    })
            }
        }, 1000);
    }, []);

    return (
        <AsyncSelect
            isMulti
            name={name}
            cacheOptions={false}
            loadOptions={loadOptions}
            defaultOptions={false}
            onBlur={onBlur}
            value={value}
            onChange={newValue => setValue(newValue)}
            classNames={{
                option: ({}) => `text-black`,
                menuList: ({}) => `text-black`,
                clearIndicator: ({}) => "text-red",
            }}
        />

    );
};

export default ContentAsyncSelect;