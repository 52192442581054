import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../utils/supabase';
import EmojiPicker from "emoji-picker-react";
import { BsEmojiSmile, BsImage, BsCameraVideo } from 'react-icons/bs';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    padding: '20px',
    backgroundColor: '#f0f2f5',
  },
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#333',
    textAlign: 'center',
  },
  messagesContainer: {
    flex: 1,
    overflowY: 'auto',
    backgroundColor: '#e0f7fa',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  messagesHeader: {
    fontSize: '20px',
    color: '#555',
    textAlign: 'center',
  },
  messageItem: {
    marginBottom: '15px',
    padding: '12px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    border: '1px solid #ddd',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    color: '#333',
  },
  media: {
    maxWidth: '100%',
    borderRadius: '8px',
    marginTop: '10px',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px',
    borderRadius: '8px',
    border: '1px solid #ddd',
    backgroundColor: '#fff',
  },
  textInput: {
    flex: 1,
    padding: '12px',
    fontSize: '16px',
    color: '#000',
    backgroundColor: '#fff',
    borderRadius: '8px',
    border: '1px solid #ddd',
    resize: 'none',
    minHeight: '50px',
  },
  sendButton: {
    padding: '12px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    fontSize: '16px',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: '0.3s',
  },
  iconButton: {
    background: 'none',
    border: 'none',
    color: '#007bff',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  emojiPicker: {
    position: 'absolute',
    bottom: '70px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 10,
  },
  fileInput: {
    display: 'none',
  },
};

const MessageChannel = () => {
  const [message, setMessage] = useState('');
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [messages, setMessages] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchMessages = async () => {
      const { data, error } = await supabase
        .from('channelmessages')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) console.error('Error fetching messages:', error);
      else setMessages(data);
    };
    fetchMessages();
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleTextChange = (e) => setMessage(e.target.value);

  const handleEmojiClick = (emojiData) => {
    setMessage((prev) => prev + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  const handleImageChange = (e) => setImage(e.target.files[0]);
  const handleVideoChange = (e) => setVideo(e.target.files[0]);

  const uploadFile = async (file, type) => {
    try {
      // Select the bucket based on the file type (image or video)
      const bucketName = type === 'images' ? 'channel_image' : 'channel_video';
  
      // Unique filename based on the current timestamp and the file's original name
      const filePath = `${new Date().getTime()}-${file.name}`;
  
      // Upload the file to Supabase storage
      const { data, error: uploadError } = await supabase.storage
        .from(bucketName)
        .upload(filePath, file, { cacheControl: '3600', upsert: false });
  
      if (uploadError) {
        console.error(`Error uploading ${type}:`, uploadError);
        return null;
      }
  
      // Retrieve the public URL of the uploaded file
      const { data: publicUrlData, error: urlError } = supabase.storage
        .from(bucketName)
        .getPublicUrl(filePath);
  
      if (urlError) {
        console.error(`Error retrieving URL for ${type}:`, urlError);
        return null;
      }
  
      return publicUrlData.publicUrl;
    } catch (error) {
      console.error("Error during file upload:", error);
      return null;
    }
  };
  

  const handleSendMessage = async () => {
    if (message.trim() || image || video) {
      let imageUrl = null;
      let videoUrl = null;
  
      // Upload media files before sending the message
      if (image) imageUrl = await uploadFile(image, 'images');
      if (video) videoUrl = await uploadFile(video, 'videos');
  
      const finalMessage = message.trim();
  
      // Send message to Supabase database with the media URL
      const { error } = await supabase.from('channelmessages').insert([
        {
          message: finalMessage,
          url: imageUrl || videoUrl || null, // Attach media URL or null if no media
          created_at: new Date().toISOString(),
        },
      ]);
  
      if (error) {
        console.error('Error sending message:', error);
      } else {
        // Update state with the new message (including media URL)
        setMessages((prevMessages) => [
          { message: finalMessage, url: imageUrl || videoUrl || null, created_at: new Date().toISOString() },
          ...prevMessages,
        ]);
      }
  
      // Reset input fields after sending the message
      setMessage('');
      setImage(null);
      setVideo(null);
    }
  };
  
  return (
    <div className="message-channel" style={styles.container}>
      <h2 style={styles.header}>Message Channel</h2>

      <div style={styles.messagesContainer}>
        <h3 style={styles.messagesHeader}>Messages</h3>
        <ul style={styles.messagesList}>
          {messages.map((msg) => (
            <li key={msg.id} style={styles.messageItem}>
              {msg.message && <p style={styles.messageText}>{msg.message}</p>}
              {msg.url && (
                /\.(jpeg|jpg|gif|png)$/i.test(msg.url) ? (
                  <img src={msg.url} alt="Sent media" style={styles.media} />
                ) : (
                  <video controls src={msg.url} style={styles.media} />
                )
              )}
              <p style={styles.messageTime}>
                {new Date(msg.created_at).toLocaleString()}
              </p>
            </li>
          ))}
          <div ref={messagesEndRef} />
        </ul>
      </div>

      <div style={styles.inputContainer}>
        <textarea
          value={message}
          onChange={handleTextChange}
          placeholder="Type a message..."
          style={styles.textInput}
        />

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button onClick={() => setShowEmojiPicker(!showEmojiPicker)} style={styles.iconButton}>
            <BsEmojiSmile size={24} />
          </button>
          {showEmojiPicker && (
            <div style={styles.emojiPicker}>
              <EmojiPicker onEmojiClick={handleEmojiClick} />
            </div>
          )}

          <label htmlFor="image-upload" style={styles.iconButton}>
            <BsImage size={24} />
            <input type="file" accept="image/*" onChange={handleImageChange} id="image-upload" style={styles.fileInput} />
          </label>

          <label htmlFor="video-upload" style={styles.iconButton}>
            <BsCameraVideo size={24} />
            <input type="file" accept="video/*" onChange={handleVideoChange} id="video-upload" style={styles.fileInput} />
          </label>

          <button onClick={handleSendMessage} style={styles.sendButton}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default MessageChannel;
