import React, {useCallback} from 'react';
import AsyncSelect from 'react-select/async';
import {useField} from "formik";
import {supabase} from "../utils/supabase";

const ContentTypeAsyncSelect = ({type = "vjs"}) => {
    const [{name, value, onBlur, onChange}, _, {setValue}] = useField("content_type_id");

    const loadOptions = useCallback((inputValue, callback) => {
        setTimeout(() => {
            if (inputValue.trim().length < 1) {
                callback([{value: "", label: "Select"}])
            } else {
                //
                supabase.from(type)
                    .select("id,name")
                    .ilike("name", `%${inputValue}%`)
                    .then(({data, error}) => {
                        if (error) console.log("error", error)
                        const map = data.map(item => ({value: item.id, label: item.name}));
                        callback([{value: "", label: "Select"}, ...map]);
                    })
            }
        }, 1000);
    }, []);

    return (
        <AsyncSelect
            name={name}
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            onBlur={onBlur}
            value={value}
            onChange={newValue => setValue(newValue)}
            classNames={{
                option: ({}) => `text-black`, menuList: props => `text-black`, clearIndicator: ({}) => "text-red"
            }}
        />

    );
};

export default ContentTypeAsyncSelect;